.deprecationBanner {
  background-color: #03a1fc;
  color: white;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 16px !important;
  position: relative;
  width: 100%;
}

.link {
  color: white;
  text-decoration: underline;

  &:hover {
    color: white;
    opacity: 0.9;
  }
}
